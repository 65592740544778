import * as React from "react"
import { processNodes, Transform } from "react-html-parser"
import { Link } from "gatsby"

import Title from "../components/atoms/title"
import Paragraph from "../components/atoms/paragraph"

export default function transform(node: any, index: number) {
  if (node.type === "tag" && node.name === "h1") {
    return (
      <Title
        className="mb-s normal-case"
        as="h1"
        style={node.attribs.style}
        key={index}
      >
        {processNodes(node.children, transform)}
      </Title>
    )
  }
  if (node.type === "tag" && node.name === "h2") {
    return (
      <Title
        className="mb-s normal-case"
        as="h2"
        style={node.attribs.style}
        key={index}
      >
        {processNodes(node.children, transform)}
      </Title>
    )
  }
  if (node.type === "tag" && node.name === "h3") {
    return (
      <Title className="mb-s" as="h3" style={node.attribs.style} key={index}>
        {processNodes(node.children, transform)}
      </Title>
    )
  }
  if (node.type === "tag" && node.name === "h4") {
    return (
      <Title className="mb-s" as="h4" style={node.attribs.style} key={index}>
        {processNodes(node.children, transform)}
      </Title>
    )
  }
  if (node.type === "tag" && node.name === "h5") {
    return (
      <Title className="mb-xs" as="h5" style={node.attribs.style} key={index}>
        {processNodes(node.children, transform)}
      </Title>
    )
  }
  if (node.type === "tag" && node.name === "h6") {
    return (
      <Title className="mb-xs" as="h6" style={node.attribs.style} key={index}>
        {processNodes(node.children, transform)}
      </Title>
    )
  }
  if (node.type === "tag" && node.name === "p") {
    return (
      <Paragraph
        className="mb-s leading-tight"
        style={node.attribs.style}
        key={index}
      >
        {processNodes(node.children, transform)}
      </Paragraph>
    )
  }
  if (node.type === "tag" && node.name === "ul") {
    return (
      <ul
        className="mb-xs leading-tight"
        style={node.attribs.style}
        key={index}
      >
        {processNodes(node.children, transform)}
      </ul>
    )
  }
  if (node.type === "tag" && node.name === "li" && node.parent.name === "ol") {
    return (
      <li
        className="sm:ml-s mb-m md:mb-l leading-tight relative"
        style={node.attribs.style}
        key={index}
      >
        <div className="absolute -left-l bg-white w-12 h-12 p-xs text-center text-xl rounded-full font-black text-primary">
          {index + 1}
        </div>
        {processNodes(node.children, transform)}
      </li>
    )
  }
  if (node.type === "tag" && node.name === "a") {
    const href = node.attribs.href
    if (href) {
      if (href.startsWith("/")) {
        return (
          <Link
            className="text-primary underline cursor-pointer"
            to={node.attribs.href}
            style={node.attribs.style}
            key={index}
          >
            {processNodes(node.children, transform)}
          </Link>
        )
      } else if (
        !(href.includes("skyrenov.fr") || href.includes("skyrenov.com"))
      ) {
        return (
          <a
            className="text-primary underline cursor-pointer"
            href={node.attribs.href}
            target="_blank"
            style={node.attribs.style}
            key={index}
          >
            {processNodes(node.children, transform)}
          </a>
        )
      } else {
        return (
          <a
            className="text-primary underline cursor-pointer"
            href={node.attribs.href}
            target={node.attribs.target}
            style={node.attribs.style}
            key={index}
          >
            {processNodes(node.children, transform)}
          </a>
        )
      }
    }
  }
}
