import * as React from "react"
import classnames from "classnames"
import { Link as GatsbyLink } from "gatsby"

import { ButtonProps } from "../../interfaces/ButtonProps"

const Button: React.FC<ButtonProps> = ({
  onclick,
  type,
  icon,
  href,
  className,
  target,
  children,
  large,
  alt,
}) => {
  const isInternalLink: boolean | undefined = href?.startsWith("/")
  const baseClassName: string = `group text-white text-xs ${
    large ? "px-m py-s" : "px-s py-xs"
  } uppercase transition duration-500 ${
    alt
      ? "bg-secondary hover:border-secondary hover:bg-primary"
      : "bg-primary hover:border-primary hover:bg-secondary"
  }  inline-flex items-center`
  if (!href) {
    return (
      <button
        type={type}
        onClick={onclick}
        className={classnames(baseClassName, className)}
      >
        {icon && <div className="group-hover:animate-wiggle mr-s">{icon}</div>}{" "}
        {children}
      </button>
    )
  } else {
    if (isInternalLink) {
      return (
        <GatsbyLink
          onClick={onclick}
          to={href}
          className={classnames(baseClassName, className)}
        >
          {icon && (
            <div className="group-hover:animate-wiggle mr-s">{icon}</div>
          )}
          {children}
        </GatsbyLink>
      )
    } else {
      return (
        <a
          onClick={onclick}
          href={href}
          target={target}
          className={classnames(baseClassName, className)}
        >
          {icon && (
            <div className="group-hover:animate-wiggle mr-s">{icon}</div>
          )}
          {children}
        </a>
      )
    }
  }
}

export default Button
