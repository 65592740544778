import { useEffect, useState } from "react"

export default function useScrollPosition() {
  if (typeof window === "undefined") return 500

  // Store the state
  const [scrollPos, setScrollPos] = useState(window.pageYOffset)

  // On Scroll
  const onScroll = () => {
    setScrollPos(window.pageYOffset)
  }

  // Add and remove the window listener
  useEffect(() => {
    window.addEventListener("scroll", onScroll)
    return () => {
      window.removeEventListener("scroll", onScroll)
    }
  })

  return scrollPos
}
