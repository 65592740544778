import * as React from "react"
import { FiXCircle } from "react-icons/fi"
import classnames from "classnames"

const Maintenance: React.FC<{
  children: React.ReactNode
  classNames?: string
}> = ({ children, classNames }) => {
  return (
    <div
      className={classnames(
        "transform transition-transform fixed w-full h-full top-0 left-0 bg-light-gray z-50 p-s sm:p-m lg:px-l lg:py-m",
        classNames
      )}
    >
      <div
        className={classnames(
          "bg-white w-full h-full p-m md:p-l overflow-auto relative shadow flex items-center justify-center",
          classNames
        )}
      >
        <div className="container">{children}</div>
      </div>
    </div>
  )
}

export default Maintenance
