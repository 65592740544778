import * as React from "react"
import classnames from "classnames"
import { FiXCircle } from "react-icons/fi"

import Paragraph from "../atoms/paragraph"
import Button from "../atoms/button"

const CookieNotice: React.FC<{ data: { message: string } }> = ({ data }) => {
  const [open, setOpen] = React.useState(false)

  function accept() {
    localStorage.setItem("cookie", "true")
    setOpen(false)
  }

  function decline() {
    localStorage.setItem("cookie", "false")
    setOpen(false)
  }

  React.useEffect(() => {
    if (localStorage.getItem("cookie")) {
      setOpen(false)
    } else {
      setOpen(true)
    }
  }, [])

  return (
    <div
      className={classnames(
        "transition-transform transform fixed w-full bottom-0 py-s z-30 bg-white py-xs shadow border-t border-light-gray",
        open ? "translate-y-0" : "translate-y-full"
      )}
    >
      <FiXCircle
        onClick={() => accept()}
        className="transition cursor-pointer absolute text-xl top-1 sm:top-3 right-1 sm:right-3 hover:text-primary"
      />
      <div className="container">
        {data.message && (
          <Paragraph className="mb-xs text-sm">{data.message}</Paragraph>
        )}
        <Button
          className="justify-center m-xxs w-full w-full sm:w-auto"
          onclick={() => accept()}
        >
          Autoriser les cookies
        </Button>
        <Button
          className="justify-center m-xxs bg-gray w-full sm:w-auto"
          onclick={() => decline()}
        >
          Utiliser uniquement les cookies nécessaires
        </Button>
      </div>
    </div>
  )
}

export default CookieNotice
