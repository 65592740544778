import * as React from "react"
import { FiPhoneCall } from "react-icons/fi"
import classNames from "classnames"

// import CtcForm from "../molecules/ctcForm"
import Paragraph from "../atoms/paragraph"

const ClickToCall: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <div
      className={classNames(
        "max-w-lg	border border-light-gray bg-white shadow-offet-white",
        className
      )}
    >
      <div className="-mt-s inline-flex -ml-s z-10 relative">
        <div className="z-10 inline-flex relative">
          <FiPhoneCall className="text-xxl text-primary" />
          <div className="ml-s">
            <Paragraph className="text-lg">
              <b>Une question ?</b> <br />
              01 84 24 01 01
            </Paragraph>
          </div>
        </div>
 
      </div>
      {/* <CtcForm /> */}
    </div>
  )
}

export default ClickToCall
