import * as React from "react"
import { FiXCircle } from "react-icons/fi"
import classnames from "classnames"

import { useAppDispatch } from "../../app/hooks"
import { toggle, close } from "../../features/modal/modal-slice"
import useKeyPress from "../../hooks/useKeyPress"

const Modal: React.FC<{ children: React.ReactNode; classNames?: string }> = ({
  children,
  classNames,
}) => {
  const dispatch = useAppDispatch()
  function handleClick() {
    dispatch(toggle())
  }
  useKeyPress("Escape", () => {
    dispatch(close())
  })

  return (
    <div
      className={classnames(
        "transform transition-transform fixed w-full h-full top-0 left-0 bg-half-gray z-50 p-s sm:p-m lg:px-l lg:py-m",
        classNames
      )}
    >
      <div
        className={classnames(
          "w-full h-full p-m md:p-l overflow-auto relative shadow flex items-center justify-center",
          classNames
        )}
      >
        <FiXCircle
          onClick={handleClick}
          className="transition-transform transform hover:rotate-180 cursor-pointer text-primary text-xxl absolute top-2 md:top-4 right-2 md:right-4"
        />
        {children}
      </div>
    </div>
  )
}

export default Modal
