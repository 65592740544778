import * as React from "react"
import classnames from "classnames"
import { Link } from "gatsby"
import { FiFacebook, FiTwitter, FiInstagram, FiLinkedin } from "react-icons/fi"
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import Paragraph from "../atoms/paragraph"
import { FooterProps } from "../../interfaces/FooterProps"

const Footer: React.FC<FooterProps> = ({ logo, data, className }) => {
  const ImgLogo = getImage(logo?.localFile)
  if (data) {
    return (
      <div className={classnames("bg-black text-white py-l mt-l", className)}>
        <div className="mx-6 flex flex-col items-center">
          <Link to="/">
            {ImgLogo && <GatsbyImage image={ImgLogo} alt={logo.altText} />}
          </Link>
          <Paragraph className="text-sm italic my-s">
            © SkyRénov {new Date().getFullYear()}, tous droits réservés.
          </Paragraph>
          {data.menu && (
            <nav className="mb-m flex flex-col text-center sm:flex-row flex-wrap">
              {data.menu.map((el, i) => {
                if (el.path) {
                  return (
                    <React.Fragment key={i}>
                      <Link
                        to={el.path}
                        className="transition-color text-primary hover:text-white px-s"
                      >
                        {el.label}
                      </Link>
                      {data.menu.length - 1 !== i && (
                        <div className="hidden sm:block border-r border-gray"></div>
                      )}
                    </React.Fragment>
                  )
                }
              })}
            </nav>
          )}
          {data.socials && (
            <div className="flex text-xl">
              {data.socials.instagram && (
                <a
                  className="text-gray hover:text-primary mx-xs"
                  href={data.socials.instagram}
                  target="_blank"
                >
                  <FiInstagram />
                </a>
              )}
              {data.socials.facebook && (
                <a
                  className="text-gray hover:text-primary mx-xs"
                  href={data.socials.facebook}
                  target="_blank"
                >
                  <FiFacebook />
                </a>
              )}
              {data.socials.twitter && (
                <a
                  className="text-gray hover:text-primary mx-xs"
                  href={data.socials.twitter}
                  target="_blank"
                >
                  <FiTwitter />
                </a>
              )}
              {data.socials.linkedin && (
                <a
                  className="text-gray hover:text-primary mx-xs"
                  href={data.socials.linkedin}
                  target="_blank"
                >
                  <FiLinkedin />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default Footer
