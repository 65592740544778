import * as React from "react"
import Footer from "./organisms/footer"
import { graphql, useStaticQuery } from "gatsby"
import { SEOContext } from "gatsby-plugin-wpgraphql-seo"
import ReactHtmlParser from "react-html-parser"

import Navbar from "./organisms/navbar"
import NavbarFixed from "./organisms/navbarFixed"
import CookieNotice from "./organisms/cookieNotice"
import Modal from "./organisms/modal"
import ClickToCall from "./organisms/clickToCall"
import { useAppSelector } from "../app/hooks"
import ScrollToTop from "./atoms/scrollToTop"
import Maintenance from "./organisms/maintenance"
import transform from "../utils/htmlParser"

const Layout: React.FC<{
  children: JSX.Element | JSX.Element[] | string | string[]
}> = ({ children }) => {
  const data = useStaticQuery(graphql`
    query LayoutQuery {
      wp {
        seo {
          contentTypes {
            service {
              metaDesc
              metaRobotsNoindex
              title
              schemaType
            }
            page {
              metaDesc
              metaRobotsNoindex
              schemaType
              title
            }
          }
          webmaster {
            googleVerify
            yandexVerify
            msVerify
            baiduVerify
          }
          schema {
            companyName
            personName
            companyOrPerson
            wordpressSiteName
            siteUrl
            siteName
            inLanguage
            logo {
              sourceUrl
              mediaItemUrl
              altText
            }
          }
          social {
            facebook {
              url
              defaultImage {
                sourceUrl
                mediaItemUrl
              }
            }
            instagram {
              url
            }
            linkedIn {
              url
            }
            mySpace {
              url
            }
            pinterest {
              url
              metaTag
            }
            twitter {
              username
            }
            wikipedia {
              url
            }
            youTube {
              url
            }
          }
        }
        unscuzzyOptions {
          acfOptions {
            maintenance
            facebook
            instagram
            twitter
            linkedin
            messageCookieNotice
            logo {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    width: 180
                    layout: CONSTRAINED
                  )
                }
              }
            }
            logoAlt {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData(
                    placeholder: TRACED_SVG
                    formats: AUTO
                    pngOptions: { compressionSpeed: 10, quality: 85 }
                    width: 200
                    layout: CONSTRAINED
                  )
                }
              }
            }
          }
        }
      }
      footerMenuQuery: wpMenu(name: { eq: "Footer" }) {
        menuItems {
          nodes {
            path
            label
          }
        }
      }
      navbarMenuQuery: wpMenu(name: { eq: "Navbar" }) {
        menuItems {
          nodes {
            path
            parentId
            label
            childItems {
              nodes {
                path
                label
              }
            }
          }
        }
      }
    }
  `)
  const options = data.wp.unscuzzyOptions.acfOptions

  const modal = useAppSelector(state => state.modal.value)

  const navbarMenu = data.navbarMenuQuery?.menuItems.nodes.filter(function (
    e: any
  ) {
    return e.parentId === null
  })
  const footerMenu = data.footerMenuQuery?.menuItems.nodes

  const { seo } = data.wp

  return (
    <SEOContext.Provider value={{ global: seo }}>
      {options.maintenance && (
        <Maintenance>
          {ReactHtmlParser(options.maintenance, {
            decodeEntities: true,
            transform,
          })}
        </Maintenance>
      )}
      <header>
        <Navbar logo={options.logo} data={navbarMenu} />
        <NavbarFixed logo={options.logo} data={navbarMenu} />
      </header>
      <main>{children}</main>
      <Modal classNames={modal ? "translate-y-0" : "-translate-y-full"}>
        <ClickToCall />
      </Modal>
      <footer>
        <Footer
          logo={options.logoAlt}
          data={{
            socials: {
              facebook: options.facebook,
              twitter: options.twitter,
              instagram: options.instagram,
              linkedin: options.linkedin,
            },
            menu: footerMenu,
          }}
        />
      </footer>
      <CookieNotice data={{ message: options.messageCookieNotice }} />
      <ScrollToTop />
    </SEOContext.Provider>
  )
}

export default Layout
