import * as React from "react"
import { FiChevronsUp } from "react-icons/fi"

export default function ScrollToTop() {
  return (
    <div
      onClick={() => {
        window[`scrollTo`]({
          top: 0,
          behavior: `smooth`,
        })
      }}
      className="cursor-pointer fixed bottom-s right-s bg-primary shadow-xl p-xs rounded-full text-white z-40"
    >
      <FiChevronsUp />
    </div>
  )
}
