import * as React from "react"
import classnames from "classnames"

import { ParagraphProps } from "../../interfaces/ParagraphProps"

const Paragraph: React.FC<ParagraphProps> = ({
  className,
  children,
  style,
}) => {
  return (
    <p className={classnames("font-text font-base", className)} style={style}>
      {children}
    </p>
  )
}
export default Paragraph
