import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import classnames from "classnames"
import { FiMenu, FiXCircle } from "react-icons/fi"
import { globalHistory } from "@reach/router"
import Link from "../atoms/link"

import NavbarLink from "../molecules/navbarLink"
import { NavbarLinkProps } from "../../interfaces/NavbarLinkProps"
import useScrollPosition from "../../hooks/useScrollPosition"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"

const NavbarFixed: React.FC<{
  logo: { localFile: FileNode; altText: string }
  data: NavbarLinkProps[]
}> = ({ logo, data }) => {
  const scrollPos = useScrollPosition()
  const [open, setOpen] = React.useState(false)
  const [scrolled, setScrolled] = React.useState(false)

  const burgerBase = "absolute transition duration-750 transform"

  React.useEffect(() => {
    scrollPos > 50 ? setScrolled(true) : setScrolled(false)
  }, [scrollPos])

  React.useEffect(() => {
    setScrolled(false)
  }, [globalHistory?.location.pathname])

  const ImgLogo = getImage(logo.localFile)

  if (data) {
    return (
      <div
        className={classnames(
          "px-s top-0 transition-transform transform fixed w-full py-xxs z-40 bg-white py-xs shadow",
          scrolled ? "lg:translate-y-0" : "lg:-translate-y-full"
        )}
      >
        <div className="relative max-w-screen-2xl mx-auto">
          <div className="relative z-50 flex justify-between items-center">
            <Link href="/">
              {ImgLogo && (
                <GatsbyImage
                  className="m-0 w-24"
                  image={ImgLogo}
                  alt={logo.altText}
                />
              )}
            </Link>
        
            <div
              className="w-6 h-6 ml-s text-lg cursor-pointer relative"
              onClick={() => setOpen(!open)}
            >
              <FiMenu
                className={classnames(
                  burgerBase,
                  open ? "scale-0 rotate-180" : "scale-100 rotate-0"
                )}
              />
              <FiXCircle
                className={classnames(
                  burgerBase,
                  open ? "scale-100 rotate-0" : "scale-0 rotate-180"
                )}
              />
            </div>
          </div>
          {!!data && (
            <nav
              className={classnames(
                "z-40 mt-m fixed right-0 top-0 bg-white h-screen transition-all flex flex-col",
                open ? "w-72 px-m py-m" : "px-0 py-0",
                !scrolled && "lg:hidden"
              )}
            >
              {data.map((el, i) => (
                <NavbarLink
                  className={open ? "inline-block" : "hidden"}
                  data={el}
                  scrolled={scrolled}
                  key={i}
                />
              ))}
            </nav>
          )}
        </div>
      </div>
    )
  } else {
    return null
  }
}

export default NavbarFixed
