import * as React from "react"
import { Link as GatsbyLink } from "gatsby"
import classnames from "classnames"

import { LinkProps } from "../../interfaces/LinkProps"

const Link: React.FC<LinkProps> = ({ href, className, target, children }) => {
  const isInternalLink: boolean = href.startsWith("/")
  const baseClassName: string =
    "transition-colors text-primary hover:text-primary"
  if (isInternalLink) {
    return (
      <GatsbyLink to={href} className={classnames(baseClassName, className)}>
        {children}
      </GatsbyLink>
    )
  } else {
    return (
      <a
        href={href}
        target={target}
        className={classnames(baseClassName, className)}
      >
        {children}
      </a>
    )
  }
}

export default Link
