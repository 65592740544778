import * as React from "react"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { FileNode } from "gatsby-plugin-image/dist/src/components/hooks"

import Link from "../atoms/link"
import NavbarCta from "../molecules/navbarCta"
import NavbarLink from "../molecules/navbarLink"
import { NavbarLinkProps } from "../../interfaces/NavbarLinkProps"

const Navbar: React.FC<{
  logo: { localFile: FileNode; altText: string }
  data: NavbarLinkProps[]
}> = ({ logo, data }) => {
  const ImgLogo = getImage(logo.localFile)
  return (
    <div className="z-20 absolute w-full bg-gradient-to-b from-white to-transparent">
      <div
        className={
          "max-w-screen-2xl	relative mx-auto hidden  lg:block w-full py-xxs"
        }
      >
        <div className="max-w-full mx-6 sm:mx-8 lg:mx-12">
          <div className="flex justify-between items-center">
            {data && (
              <nav className="flex flex-1">
                {data.map((el, i) => {
                  return <NavbarLink data={el} key={i} />
                })}
              </nav>
            )}
            <Link href="/">
              {ImgLogo && (
                <GatsbyImage
                  className="mt-4 mx-auto mb-s"
                  image={ImgLogo}
                  alt={logo.altText}
                />
              )}
            </Link>
      
          </div>
        </div>
      </div>
    </div>
  )
}

export default Navbar
