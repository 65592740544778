import * as React from "react"
import { FiChevronDown } from "react-icons/fi"
import { useLocation } from "@reach/router"
import classnames from "classnames"

import Paragraph from "../atoms/paragraph"
import Link from "../atoms/link"
import { NavbarLinkProps } from "../../interfaces/NavbarLinkProps"

const NavbarLink: React.FC<{
  data: NavbarLinkProps
  className?: string
  scrolled?: boolean
}> = ({ data, className, scrolled }) => {
  const { pathname } = useLocation()
  const { childItems, path, label } = data
  const isActive = pathname === path
  return (
    <div className={classnames("group mr-m relative", className)}>
      <Link
        href={path}
        className={classnames(
          "flex items-center py-xxs",
          isActive ? "font-semibold text-primary" : "text-black"
        )}
      >
        <Paragraph>{label}</Paragraph>
        {childItems && childItems.nodes.length > 0 && (
          <FiChevronDown className="ml-xs" />
        )}
      </Link>
      {childItems && childItems.nodes.length > 0 && (
        <div
          className={classnames(
            "group-hover:flex flex lg:hidden bg-white w-min p-s flex-col z-40",
            !scrolled && "lg:shadow lg:absolute"
          )}
        >
          {childItems.nodes.map((child, i) => (
            <Link
              href={child.path}
              className="my-xxs whitespace-nowrap text-dark"
              key={i}
            >
              {child.label}
            </Link>
          ))}
        </div>
      )}
    </div>
  )
}

export default NavbarLink
